import axios from 'axios'
import store from '../store'

export default () => {
  return axios.create({
    baseURL: `https://orders.balkan1936.co.il:3031/agent`,
    // baseURL: `https://orders.nirotek.com:8081/`,
    headers: {
      Authorization: `Bearer ${store.state.AuthModule.token}`
    }
  })
}