<template>
  <div class="singel-doc-page">
    <div v-if="getOrder" class="OrderView d-flex flex-column ga-4">
      <DocInfo
        :docNumber="getOrder.ord"
        :docTypeName="$t('SingleOrder.OrderNumber')"
        :editable="(editable && isOnEditOrderMode) || iscart"
        :theDoc="getOrder"
        :iscart="iscart"
        :mode="mode"
      />

      <DocProducts
        :docTypeName="$t('SingleOrder.orderDetails')"
        :orderLines="getOrder?.OrderLines"
        :cust="getOrder?.Customer?.cust"
        :mode="mode"
        :editable="(editable && isOnEditOrderMode) || iscart"
      >
        <template #doc-summary>
          <InnerBox
            v-if="getOrder?.OrderLines?.length > 0"
            :fontWeight="700"
            class="order-summary"
            :title="$t('SingleOrder.orderSummaryTitle')"
          >
            <template #content>
              <div v-if="true" class="d-flex flex-column ga-2">
                <div class="d-flex justify-space-between order-info">
                  <label>{{ $t("SingleOrder.orderCountItems") }}</label>
                  <span>{{ orderItemsCount }}</span>
                </div>
                <div class="d-flex justify-space-between order-info">
                  <label>{{ $t("SingleOrder.orderBoxesCount") }}</label>
                  <span>{{ orderBoxesCount }}</span>
                </div>
                <div class="d-flex justify-space-between order-info">
                  <label>{{ $t("SingleOrder.priceBeforeTax") }}</label>
                  <span
                    >{{ currency }}{{ formatPrice(priceBeforeTaxSum) }}</span
                  >
                </div>
                <div class="d-flex justify-space-between order-info">
                  <label
                    >{{ $t("SingleOrder.tax") }}
                    {{ `(${getOrder.tax}%)` }}</label
                  >
                  <span>{{ currency }}{{ formatPrice(priceTaxAmount) }}</span>
                </div>
                <div class="d-flex justify-space-between order-info">
                  <label>{{ $t("SingleOrder.priceIncludeTax") }}</label>
                  <span
                    >{{ currency }}{{ formatPrice(priceIncludeTaxSum) }}</span
                  >
                </div>
              </div>
            </template>
          </InnerBox>
        </template>
      </DocProducts>

      <DocNotes :mode="mode" :editable="false" :notes="getOrder.notes" />
      <Invocies :invoices="getOrder.Deliveries" v-if="getOrder.Deliveries.length > 0" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import DocInfo from "../helpers/DocInfo.vue";
import DocNotes from "../helpers/DocNotes.vue";
import DocProducts from "../helpers/DocProducts.vue";
import InnerBox from "../UI/InnerBox.vue";
import Invocies from "../helpers/Invocies.vue";

export default {
  components: {
    DocInfo,
    DocNotes,
    DocProducts,
    InnerBox,
    Invocies,
  },
  data() {
    return {
      theOrder: null,
      cartOrOrder: "",
      iscart: false,
      hasDeliveries: false,
      editable: false,
      activeOrder: false,
      hasChanges: false,
      orderEmpty: false,
      PriorityAnswer: false,
      savedBeforeSendOrder: false,
      newOrderNumber: "",
      orderNumber: "",
      newDocReference: "",
      newDocShippingCode: "",
      newDocNotes: "",
      isOnEditOrderMode: false,
      currency: "₪",
    };
  },
  watch: {
    theOrder() {
      if (this.editable && typeof this.theOrder.User != "undefined") {
        this.selectedContactUser = this.theOrder.User.phone;
      }
    },
  },
  methods: {
    formatPrice(price) {
      if (Number.isInteger(price)) {
        return price.toString();
      } else {
        return price.toFixed(2);
      }
    },
    ...mapActions({
      toggleFavorite: "Parts/toggleFavorite",
      removeOrderLine: "Orders/removeOrderLine",
      updateOrderLine: "Orders/updateOrderLine",
    }),
  },
  computed: {
    mode() {
      return this.iscart ? "cart" : "orders";
    },
    priceTaxAmount() {
      return this.priceIncludeTaxSum - this.priceBeforeTaxSum;
    },
    priceIncludeTaxSum() {
      return this.priceBeforeTaxSum + (this.priceBeforeTaxSum * (this.getOrder.tax / 100));
    },
    priceBeforeTaxSum() {
      return this.getOrder.OrderLines.reduce((acc, line) => {
        return acc + line.partPrice * line.quant;
      }, 0);
    },
    orderBoxesCount() {
      return this.getOrder.OrderLines.reduce((acc, line) => {
        return acc + line.quant;
      }, 0);
    },
    orderItemsCount() {
      return this.getOrder.OrderLines.length;
    },
    orderItemsSum() {
      return this.getOrder.OrderLines.reduce((acc, line) => {
        return acc + line.quant;
      }, 0);
    },
    ...mapGetters({
      Sites: "Sites/getSites",
      getParts: "Parts/getParts",
      getUserPhone: "AuthModule/getUserPhone",
      getCustData: "AuthModule/getCustomerData",
      isLoading: "isLoading",
      getOrder: "Orders/getOrder",
    }),
  },
};
</script>

<style scoped lang="scss">
.singel-doc-page {
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .singel-doc-page {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.order-summary {
  max-width: 320px;

  label {
    font-size: $mediumTextSize;
    font-weight: 400;
    line-height: 21.33px;
    text-align: right;
    color: $orderLineColor;
  }

  span {
    font-size: $mediumTextSize;
    font-weight: 700;
    line-height: 21.33px;
    text-align: right;
    color: #434343;
  }
}
.inner-box-title {
  font-weight: 700 !important;
}

.order-butttons-container {
  display: flex;
  gap: 20px;
}

@media only screen and (max-width: 601px) {
  .order-butttons-container {
    justify-content: space-around;
  }
}

.shipping-info {
  display: flex;
  gap: 30px;
}

.shipping-actions {
  display: grid;
  grid-row-gap: 10px;
}

.shipping-address,
.shipping-number {
  font-size: $smallTextSize;
}
.shipping-number {
  font-weight: 600;
}
.shipping-date {
  font-size: $extraSmallTextSize;
}
.light-box-headline {
  margin-bottom: 30px;
}
p {
  margin: 0;
}

select {
  display: block;
  border-radius: 10px;
  border-color: lightgrey;
  font-size: $mediumTextSize;
  font-weight: 400;
  line-height: 21.33px;
  text-align: right;
}

.inner-box {
  textarea {
    border: unset;
  }
  input {
    border: unset !important;
  }
}
textarea {
  height: 9rem;
  border-radius: 7px;
  padding: 15px;
  border-color: #dbdbdb;
  width: 100%;
}
textarea:focus {
  outline: none !important;
  border: 1px solid #6daf2d57;
  box-shadow: 0 0 10px #6daf2d69;
}

h1 {
  font-size: $largTextSize;
  font-weight: 600;
}

.doc-number-title {
  font-weight: 400;
  color: $orderLineColor;
}

input[type="date"],
input[type="text"] {
  width: 33% !important;
  display: inline !important;
  font-size: $smallTextSize !important;
}

@media (max-width: 601px) {
  .OrderView > div {
    margin-bottom: 40px !important;
  }
  .row .col:last-child {
    padding-right: 10px !important;
  }
}
.shipping-actions a {
  font-size: $smallTextSize !important;
  background: $secondaryBackgroundButtonColor !important;
}
.shipping-actions {
  margin-top: 20px !important;
}
.send-order {
  display: inline-flex;
  align-items: center;

  font-size: $mediumTextSize;
  background: $controlPanelMainColor;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: right;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 15px;
  padding: 25px;
  color: white;
  max-width: max-content;

  border: i {
    margin-right: 10px;
  }
  i:lang(en) {
    padding-right: 10px;
    transform: rotate(180deg);
  }
}

@media only screen and (max-width: 601px) {
  .send-order:first-of-type {
    max-width: unset !important;
    width: 100% !important;
  }
}

.send-order.orders {
  background: $ordersMainColor !important;
  font-size: $smallTextSize !important;
  padding-bottom: 27px;
}
</style>
