<template>
  <div>
    <WrapperTableLine
      class="customer-wrapper-line"
      :isBtnExpand="isBtnExpand"
      :hightExpand="`${modalHeight}px`"
    >
      <div
        style="width: 100%"
        class="d-flex align-center justify-space-between"
      >
        <TableInfoLine
          class="table-line flex-2"
          :title="$t(`customers.table.columns.customer`)"
          :value="custDes"
        />
        <TableInfoLine
          class="table-line"
          :title="$t(`customers.table.columns.customerTypeStatus`)"
          :value="customer?.isActive ? 'פעיל' : 'לא פעיל'"
        />
        <TableInfoLine
          class="table-line"
          :title="$t(`customers.table.columns.distLine`)"
          :value="customer.DistLine.linedes"
        />
        <TableInfoLine
          class="table-line"
          :title="$t(`customers.table.columns.distLineDays`)"
          :value="lineDays.join(', ')"
        />
        <TableInfoLine
          class="table-line"
          :title="$t(`customers.table.columns.agent`)"
          :value="customer.Agent.agentname"
        />
        <TableInfoLine
          class="table-line"
          :title="$t(`customers.table.columns.lastOrder`)"
          :value="customer.lastOrderDate ? lastOrderDateFormat : 'אין הזמנות'"
        />
        <TableInfoLine
          class="table-line"
          :title="$t(`customers.table.columns.balance`)"
          :value="`₪${
            customer.balance < 0
              ? formatPrice(customer.balance) * -1
              : formatPrice(customer.balance)
          } ${customer.balance < 0 ? 'זכות' : ''}`"
        />
        <v-icon
          @mouseover="changeIcon('hover')"
          @mouseleave="changeIcon('unhover')"
          @click="openInvoiceTableModal"
          color="#801512"
          :disabled="!customer?.Deliveries?.length > 0"
          :icon="icon"
        />
        <div style="width: 100px" />
      </div>
      <TableActions
        :isCustCanCreateAnOrder="Boolean(customer.isActive)"
        :agnetInfo="customer.Agent"
        :cust="customer.cust"
        :id="cust"
        :isBtnExpand="isBtnExpand"
        :backgroundColor="'#36b079'"
        @close-expand="$emit('close-expand')"
        @expand-btn="expandBtn"
        @setHeight="setHeight"
        mode="customer"
      />
    </WrapperTableLine>
    <InvoiceTableModal
      v-if="isInvoiceTableModalOpen"
      :invoicesList="customer?.Deliveries"
      :custDes="customer.custdes"
      :openModal="isInvoiceTableModalOpen"
      @closeModal="isInvoiceTableModalOpen = false"
    />
  </div>
</template>

<script>
import TableActions from "./TableActions.vue";
import TableInfoLine from "./TableInfoLine.vue";
import WrapperTableLine from "./WrapperTableLine.vue";
import InvoiceTableModal from "../UI/modals/InvoiceTableModal.vue";
import { getNextDays } from "../../dates";
import moment from "moment";
export default {
  emits: ["set-current-expand-btn", "close-expand"],
  components: {
    WrapperTableLine,
    TableInfoLine,
    TableActions,
    InvoiceTableModal,
  },
  props: {
    customer: {
      type: Object,
    },
    cust: {
      type: Number,
    },
    currentExpandBtn: {
      type: Number,
    },
  },
  data() {
    return {
      icon: "mdi mdi-eye-outline",
      isInvoiceTableModalOpen: false,
      modalHeight: 0,
    };
  },
  computed: {
    lastOrderDateFormat() {
      return moment(this.customer.lastOrderDate).format("DD/MM/YYYY");
    },
    custDes() {
      return this.customer.custdes;
    },
    lineDays() {
      return getNextDays(this.customer.DistLine);
    },
    isBtnExpand() {
      if (this.currentExpandBtn === this.customer.cust) return true;
      return false;
    },
    alignWrapper() {
      if (this.isBtnExpand) return "start";
      return "center";
    },
  },
  methods: {
    formatPrice(price) {
      if (Number.isInteger(price)) {
        return price.toString();
      } else {
        return price.toFixed(2);
      }
    },
    openInvoiceTableModal() {
      this.isInvoiceTableModalOpen = !this.isInvoiceTableModalOpen;
    },
    changeIcon(mode) {
      switch (mode) {
        case "hover":
          this.icon = "mdi mdi-eye";
          break;
        case "unhover":
          this.icon = "mdi mdi-eye-outline";
          break;
      }
    },
    setHeight(setHeight) {
      this.modalHeight = setHeight + 30;
    },
    expandBtn() {
      if (!this.isBtnExpand) {
        this.$emit("set-current-expand-btn", this.customer.cust);
      } else {
        this.$emit("set-current-expand-btn", false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.table-line {
  flex: 1;
  min-width: 120px;
  max-width: 140px;
}

.customer-wrapper-line {
  display: flex;
  justify-content: space-between;
  align-items: v-bind(alignWrapper);
}

.v-icon {
  cursor: pointer !important;
}

@media only screen and (max-width: 1440px) {
  .customer-wrapper-line {
    width: fit-content;
  }

  .table-line {
    min-width: 200px;
  }
}
</style>
