import { createApp } from "vue";

import App from "./App.vue";
import router from "./router.js";
import store from "./store/index";
import { createI18n } from "vue-i18n";
import hebrew from "./translations/hebrew";
import english from "./translations/english";
// import "./registerServiceWorker.js";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import '@mdi/font/css/materialdesignicons.css'

const i18n = createI18n({
  locale: "he", // set locale
  fallbackLocale: "he", // set fallback locale
  messages: {
    he: hebrew,
    en: english,
  },
});

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: "he",
    messages: {
      he: hebrew,
      en: english,
    },
  },
});

const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);
app.use(vuetify);

router.isReady().then(function () {
  app.mount("#app");
});
