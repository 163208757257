<template>
  <div class="lightbox">
    <div class="d-flex justify-space-between flex-wrap ga-5 light-box-headline">
      <div class="d-flex align-end">
        <div class="light-box-number" :class="mode">{{ boxNumber }}</div>
        <span class="light-box-title">
          <slot name="title"></slot>
        </span>
      </div>
      <slot name="top-left-action-btn"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    boxNumber: {
      type: Number,
    },
    mode: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.light-box-headline {
  margin-bottom: 30px;
}

@media (max-width: 601px) {
  .lightbox {
    padding: 0;
    border: unset;
  }
  .light-box-headline {
    flex-direction: column-reverse;
  }
}
.lightbox {
  border: 1px solid #ececec;
  margin: 10px 0;

  border-radius: 20px;
  padding: 20px;
}

.light-box-number {
  font-size: $smallTextSize;
  display: inline;
  padding: 3px 9px;
  border-radius: 50%;
  background-color: $secondaryColor;
  color: white;
  width: 28px;
  height: 28px;
  text-align: center;
  margin-left: 15px;
  float: right;
}

.light-box-number.orders {
  background-color: $ordersMainColor;
}
.light-box-number.finances {
  background-color: $customerMainColor;
}
.light-box-number.invoices {
  background-color: $customerMainColor;
}
.light-box-number.cart {
  background-color: $controlPanelMainColor;
}
.light-box-number:lang(en) {
  margin-left: 0px;
  margin-right: 15px;
  float: left;
}

.light-box-title {
  font-size: $mediumTextSize;
  font-weight: 500;
  line-height: 21.33px;
  text-align: right;
}
</style>
