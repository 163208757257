<template>
  <div>
    <div class="customers-page">
      <CustomersTable :customersList="filteredCustomerList" />
    </div>
  </div>
</template>

<script>
import CustomersTable from "../helpers/CustomersTable.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CustomersTable,
  },
  data() {
    return {
      filteredCustomerList: [],
    };
  },
  watch: {
    "$route.query"() {
      this.initFilter();
    },
    "$route.params"() {
      this.initFilter();
    },
  },
  mounted() {
    this.initFilter();
  },
  methods: {
    initFilter() {
      this.filteredCustomerList = this.getCustomers;
      const categoriesKeys = Object.keys(this.$route.query);
      const paramsKeys = Object.keys(this.$route.params);

      for (let categoryKey of categoriesKeys) {
        this.filterCustomers(categoryKey, this.$route.query[categoryKey]);
      }

      for (let paramKey of paramsKeys) {
        this.filterCustomers(paramKey, this.$route.params[paramKey]);
      }
    },
    filterCustomers(categoryKey, categoryValue) {
      let filteredCustomers = this.filteredCustomerList;
      let newFilteredCustomers = [];
      newFilteredCustomers = filteredCustomers.filter((cust) => {
        let isFiltered = false;

        switch (categoryKey) {
          case "distLine":
            isFiltered = cust.DistLine.linekey === parseInt(categoryValue);
            break;
          case "agentId":
            isFiltered = cust.Agent.agent === parseInt(categoryValue);
            break;
          case "custId":
            isFiltered = cust.cust === parseInt(categoryValue);
            break;
          case "status":
            isFiltered = (cust.isActive ? 1 : 0) === parseInt(categoryValue);
            break;
          case "custType":
            if (categoryValue === "allcustomers") {
              isFiltered = true;
            } else if (categoryValue === "appcustomers") {
              isFiltered = cust.isInApp;
            }
            break;
        }
        return isFiltered;
      });
      this.filteredCustomerList = newFilteredCustomers;
    },
  },
  computed: {
    ...mapGetters({
      getCustomers: "Customers/getCustomers",
      getCustomersDistLines: "Customers/getCustomersDistLines",
    }),
  },
};
</script>

<style scoped lang="scss">
//style for page with scope
@media only screen and (max-width: 601px) {
  .button-container {
    gap: 10px;
    margin-bottom: 20px !important;
    flex-direction: column !important;
  }

  .customers-page {
    padding: 10px !important;
    margin-top: 10px !important;
    width: 100vw;
    // max-width: calc(100vw - 40px);
  }
  .mobile-nav-buttons {
    // margin-right: 15px;
  }
  .date-filters {
    gap: 20px !important;
  }
}

.customers-page {
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  .customers-page {
    padding: 10px !important;
  }
}

.modal-content {
  font-size: $mediumTextSize;
  color: $modalContentColor;
  font-weight: 400;
  line-height: 21.33px;
  text-align: center;
  margin-top: -20px;

  .email {
    color: #36b079;
  }
}
.finances-page {
  margin-right: 20px;
  padding-bottom: 50px;
  padding-right: 40px;
  padding-left: 40px;
}

.date-filters {
  display: flex;
  gap: 40px;
}
.button-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}
</style>
