function truncateText(text, maxLength) {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
}

module.exports = {
  truncateText,
};
