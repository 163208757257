<template>
  <div>
    <WrapperTableLine
      class="order-wrapper-line"
      :isBtnExpand="isBtnExpand"
      :hightExpand="`${modalHeight}px`"
    >
      <div
        style="width: 100%"
        class="d-flex align-center justify-space-between"
      >
        <TableInfoLine
          class="table-line"
          :title="$t(`orders.table.columns.customer`)"
          :value="custDes"
        />
        <TableInfoLine
          class="table-line"
          :title="$t(`orders.table.columns.order`)"
          :value="order.ord + ''"
        />
        <TableInfoLine
          class="table-line"
          :title="$t(`orders.table.columns.distLine`)"
          :value="order.Customer.DistLine.linedes"
        />
        <TableInfoLine
          class="table-line"
          :title="$t(`orders.table.columns.distLineDays`)"
          :value="lineDays.join(', ')"
        />
        <TableInfoLine
          class="table-line"
          :title="$t(`orders.table.columns.orderDate`)"
          :value="fomartOrderDate"
        />
        <TableInfoLine
          class="table-line"
          :title="$t(`orders.table.columns.agent`)"
          :value="order.Customer.Agent.agentname"
        />
        <TableInfoLine
          class="table-line"
          :title="`${formatPrice(partOrderWeightSum)} ${$t(
            'orders.table.columns.kg'
          )}`"
          :value="`₪${formatPrice(orderSum)}`"
        />
      </div>
      <TableActions
        mode="order"
        :agnetInfo="order.Customer.Agent"
        :id="orderId"
        :status="order?.Status"
        :cust="order.Customer.cust"
        :isBtnExpand="isBtnExpand"
        @expand-btn="expandBtn"
        @close-expand="$emit('close-expand')"
        :backgroundColor="'#5c7be9'"
        @setHeight="setHeight"
      />
    </WrapperTableLine>
  </div>
</template>

<script>
import moment from "moment";
import TableActions from "./TableActions.vue";
import TableInfoLine from "./TableInfoLine.vue";
import WrapperTableLine from "./WrapperTableLine.vue";
import { getNextDays } from "../../dates";
export default {
  emits: ["set-current-expand-btn", "close-expand"],
  components: {
    WrapperTableLine,
    TableInfoLine,
    TableActions,
  },
  props: {
    order: {
      type: Object,
    },
    orderId: {
      type: Number,
    },
    currentExpandBtn: {
      type: Number,
    },
  },
  data() {
    return {
      icon: "mdi mdi-eye-outline",
      modalHeight: "",
    };
  },
  computed: {
    lineDays() {
      return getNextDays(this.order.Customer.DistLine);
    },
    fomartOrderDate() {
      return moment(this.order.duedate).format("DD/MM/YYYY");
    },
    orderSum() {
      if (this.order?.OrderLines) {
        const sum = this.order.OrderLines.reduce((acc, orderLine) => {
          return acc + (orderLine?.partPrice ?? 0 * orderLine.quant);
        }, 0);
        const tax = sum * (this.order.tax / 100);
        return sum + tax;
      }
      return 0;
    },
    partOrderWeightSum() {
      if (this.order?.OrderLines) {
        return this.order.OrderLines.reduce((acc, orderLine) => {
          return acc + orderLine?.Part?.patrweight ?? 0;
        }, 0);
      }
      return 0;
    },
    custDes() {
      if (this.order.Customer.custdes.length >= 15) {
        return this.order.Customer.custdes.slice(0, 15) + "...";
      }
      return this.order.Customer.custdes;
    },
    isBtnExpand() {
      if (this.currentExpandBtn === this.order.ord) return true;
      return false;
    },
    alignWrapper() {
      if (this.isBtnExpand) return "start";
      return "center";
    },
  },
  methods: {
    formatPrice(price) {
      if (Number.isInteger(price)) {
        return price.toString();
      } else {
        return price.toFixed(2);
      }
    },
    setHeight(setHeight) {
      this.modalHeight = setHeight + 20;
    },
    expandBtn() {
      if (!this.isBtnExpand) {
        this.$emit("set-current-expand-btn", this.order.ord);
      } else {
        this.$emit("set-current-expand-btn", 0);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.table-line {
  min-width: 120px;
  max-width: 160px;
}

.order-wrapper-line {
  display: flex;
  justify-content: space-between;
  align-items: v-bind(alignWrapper);
}

.v-icon {
  cursor: pointer !important;
}

@media only screen and (max-width: 1440px) {
  .order-wrapper-line {
    width: fit-content;
  }

  .table-line {
    min-width: 200px;
  }
}
</style>
