import moment from "moment";
export function hebrewFormatDate(orderDate) {
  const hebrewMonths = [
    "ינו",
    "פבר",
    "מרץ",
    "אפר",
    "מאי",
    "יונ",
    "יול",
    "אוג",
    "ספט",
    "אוק",
    "נוב",
    "דצמ",
  ];

  const date = new Date(orderDate);

  const day = date.getDate();

  const monthIndex = date.getMonth();

  const year = date.getFullYear();

  const dayName = date.toLocaleString("he-IL", {
    weekday: "narrow",
  });

  return `יום ${dayName} ${day} ב${hebrewMonths[monthIndex]} ${year}`;
}

export function TodayFormat() {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}T00:00:00+02:00`;
  return formattedDate;
}
export function dateToDisplay(date) {
  return moment(date).format("DD-MM-YY");
}
export function isDateAfterToday(date) {
  return moment(date).isAfter(moment().startOf("day"));
}

export function formatToInputDate(taskdate) {
  let date = new Date(taskdate);

  if (isNaN(date)) {
    console.log("Invalid date string");
    return;
  }

  // Format the date as yyyy-MM-dd
  let formatted =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2);

  return formatted;
}

export function formatDateToPriority(taskdate) {
  const formattedDateString = `${formatToInputDate(taskdate)}T00:00:00+02:00`;

  return formattedDateString;
}

export function getNextDays(days) {
  const today = new Date();
  // Array to store output
  const nextDays = [];

  // Check each day
  Object.keys(days).forEach((key) => {


    const todayKeyDay = (today.getDay() + 1) % 7;
    // If day is true
    if (key.startsWith("day") && days[key] === true) {
      // Get the weekday number (0=Sunday, 1=Monday etc.)
      const dayOfWeek = parseInt(key.replace("day", ""));

      // Calculate next date
      const next = new Date(today);
      const diff = dayOfWeek - todayKeyDay;
      if (diff > 0) {
        const nextDay = next.getDate() + diff;
        next.setDate(nextDay);
      } else {
        const nextWeek = next.getDate() + (7 - todayKeyDay);
        next.setDate(nextWeek + dayOfWeek);
      
      }

      // Add to output
      nextDays.push(getDayText(next));
    }
  });

  return nextDays.slice(0, 3);

  // return nextDays
  //   .slice()
  //   .sort((a, b) => b.title.localeCompare(a.title))
  //   .slice(0, 3);
}

// Helper function to return text
function getDayText(date) {
  const day = date.toLocaleString("he-IL", { weekday: "long" });
  return `${day}`;
}

// function formatDate(date, payload) {
//   const { delimiter, reverse, ltrFormat } = payload;
//   if (ltrFormat) {
//     const parts = date.split("/");
//     const day = parts[0];
//     const month = parts[1];
//     const year = parts[2];
//     return `${month}${delimiter}${day}${delimiter}${year}`;
//   }
//   const dateObj = new Date(date);
//   const month = dateObj.getMonth() + 1;
//   const day = dateObj.getDate();
//   const year = dateObj.getFullYear();
//   if (reverse) return `${year}${delimiter}${month}${delimiter}${day}`;
//   return `${day}${delimiter}${month}${delimiter}${year}`;
// }
