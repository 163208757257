<template>
  <div id="pageStretcher">
    <TheHeader></TheHeader>
    <div style="flex: 1" class="app-body">
      <v-container
        :fluid="
          $route.name === 'Category' || $route.name === 'home' ? true : false
        "
        class="container"
        :class="{ RestLayout: $route.name === 'loginPage' }"
      >
        <v-row
          :class="
            $route.name === 'Category' || $route.name === 'home'
              ? 'flex-row-reverse'
              : ''
          "
        >
          <v-col
            v-if="$route.name !== 'home' || this.isDesktop || this.isLaptop"
            :class="[
              $route.name === 'loginPage' ? 'nav-login' : '',
              $route.name === 'home' ? 'side-bar-cart' : '',
              isOpenMobileSideBar ? 'mobile-side-bar' : 'side-bar',
            ]"
            cols="12"
            md="7"
            xl="3"
          >
            <router-view name="sideBar"></router-view>
          </v-col>
          <!-- <v-slide-y-reverse-transition>
            <v-col class="mobile-side-bar" v-if="isOpenMobileSideBar">
              <router-view name="mobileSideBar"></router-view>
            </v-col>
          </v-slide-y-reverse-transition> -->
          <v-col class="pull-xl-3 main">
            <main v-if="isMainContentShowing" id="mainapp">
              <router-view></router-view>
            </main>
          </v-col>
        </v-row>
      </v-container>

      <router-view name="resetPassPage"></router-view>
    </div>
    <TheFooter></TheFooter>
    <Toast />
  </div>
</template>

<script>
import TheFooter from "./components/layout/TheFooter.vue";
import TheHeader from "./components/layout/TheHeader.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import Toast from "./components/UI/Toast.vue";

export default {
  name: "App",
  components: {
    TheFooter,
    TheHeader,
    Toast,
  },

  mounted() {
    document.getElementById("dolimo_loader").style.display = "none";
    this.setLang();
    // var prevScrollpos = window.pageYOffset;
    // this.logOffAfterTime();
    // window.onscroll = function () {
    //   var currentScrollPos = window.pageYOffset;
    //   if (currentScrollPos <= 0) {
    //     console.log("take thge header down");
    //     document.querySelector("header").classList.remove("header-up");
    //     document.querySelector(".side-bar").classList.remove("header-up");

    //     if (!this.isMobile) {
    //       document.querySelector(".side-bar").classList.remove("header-up");
    //     }

    //     if (this.isMobile) {
    //       document.querySelector(".option-selected-conteiner").style.top =
    //         "1px";
    //       //   .classList.remove("header-up");
    //     }
    //     if (this.isTablet) {
    //       document.querySelector(".option-selected-conteiner").style.top =
    //         "137px";
    //     }
    //   } else {
    //     if (prevScrollpos > currentScrollPos) {
    //       console.log("take the header down");
    //       document.querySelector("header").classList.remove("header-up");
    //       if (!this.isMobile) {
    //         document.querySelector(".side-bar").classList.remove("header-up");
    //       }

    //       if (this.isMobile) {
    //         document
    //           .querySelector(".option-selected-conteiner")
    //           .classList.remove("header-up");
    //       }
    //     } else {
    //       document.querySelector("header").classList.add("header-up");
    //       document.querySelector(".side-bar").classList.add("header-up");
    //       if (!this.isMobile) {
    //         document.querySelector(".side-bar").classList.add("header-up");
    //       }

    //       if (this.isMobile) {
    //         document.querySelector(".option-selected-conteiner").style.top =
    //           "92.59px";
    //         document
    //           .querySelector(".option-selected-conteiner")
    //           .classList.add("header-up");
    //       }
    //       if (this.isTablet) {
    //         document.querySelector(".option-selected-conteiner").style.top =
    //           "23.59px";
    //       }

    //       console.log("take the header up");
    //     }
    //   }

    //   prevScrollpos = currentScrollPos;
    // };
  },
  computed: {
    ...mapGetters({
      isUserLogin: "AuthModule/isAuthenticated",
      getUserLang: "AuthModule/getUserLang",
      isMobile: "isMobile",
      isLaptop: "isLaptop",
      isTablet: "isTablet",
      isDesktop: "isDesktop",
      isOpenMobileSideBar: "isOpenMobileSideBar",
    }),
    isMainContentShowing() {
      if (this.$store.state.isMobile || this.$store.state.isTablet) {
        if (this.$store.state.openMenu) {
          return true;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  created() {
    // console.log(isTablet);
    this.setScreenSizes();
    addEventListener("resize", () => {
      this.setScreenSizes();
      this.logOffAfterTime();
    });
    // window.addEventListener("languagechange", function () {
    //   alert("languagechange event detected!");
    // });
  },
  unmounted() {
    window.removeEventListener("resize", () => {
      this.setScreenSizes();
      this.logOffAfterTime();
    });
  },
  watch: {
    "$store.state.AuthModule.token": function () {
      this.setLang();
    },
  },
  methods: {
    logOffAfterTime() {
      if (this.isTablet || this.isMobile) {
        if (this.timerToAutoLogout) {
          clearTimeout(this.timerToAutoLogout);
        }
        this.$el.addEventListener("touchmove", () => {
          if (this.isUserLogin) {
            clearTimeout(this.timerToAutoLogoutMobile);
            this.timerToAutoLogoutMobile = setTimeout(() => {
              this.logout();
              this.$router.go(0);
            }, this.minute * 10);
          }
        });
      }

      if (!this.isTablet && !this.isMobile) {
        if (this.timerToAutoLogoutMobile) {
          clearTimeout(this.timerToAutoLogoutMobile);
        }
        this.$el.addEventListener("mousemove", () => {
          if (this.isUserLogin) {
            clearTimeout(this.timerToAutoLogout);
            this.timerToAutoLogout = setTimeout(() => {
              this.logout();
              this.$router.go(0);
            }, this.minute * 10);
          }
        });
      }
    },
    setLang() {
      if (this.isUserLogin) {
        this.$i18n.locale = this.getUserLang;
        document.dir = this.$i18n.messages[this.$i18n.locale].direction;
        document.documentElement.lang = this.$i18n.locale;
        this.$vuetify.locale.current = this.$i18n.locale;
      }
    },
    setScreenSizes() {
      const width = window.innerWidth;
      this.$store.commit("setIsDesktop", width >= 1500);
      this.$store.commit("setIsLaptop", width >= 993 && width < 1500);
      this.$store.commit("setIsTablet", width >= 768 && width < 993);
      this.$store.commit("setIsMobile", width < 602);
    },
    ...mapActions({
      logout: "AuthModule/logout",
    }),
  },
  data() {
    return {
      textfloat: "right",
      timerToAutoLogoutDesktop: null,
      timerToAutoLogoutMobile: null,
      minute: 60000,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/main.scss";

@media only screen and (max-width: 601px) {
  .v-row {
    flex-direction: column-reverse !important;
  }
}
.mobile-side-bar {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 5px !important;
  background: white;
  overflow-y: scroll;
}
// כדי להוריד את layout
.RestLayout {
  max-width: unset !important;
  .main {
    padding: 0px !important;
    max-width: unset !important;
    flex: 1 0 !important;
  }
  #mainapp {
    margin: 0px !important;
  }
  .side-bar {
    position: relative !important;
    display: grid;
  }
}
.app-body{
  position: relative;
  // top: 160px;
}
// .container{
//   height: calc(100vh - 160px);
// }
.v-row {
  height: 100%;
}
.side-bar-cart {
  height: 100%;
  box-shadow: 5px 0px 20px 0px rgb(0 0 0 / 5%);
  padding-bottom: 0px !important;
}
.main {
  height: 100%;
}
input:focus-visible {
  outline: none !important;
}

* {
  touch-action: manipulation;
}
// [type="checkbox"].filled-in + span:not(.lever):before,
// [type="checkbox"].filled-in + span:not(.lever):after {
//   left: unset !important;
// }
.v-container {
  padding: 0px !important;
  // max-width: 95% !important;
  width: unset !important;
}
body.stop-scrolling {
  // overflow: hidden !important;
}
header.header-up,
.header-up {
  transform: translateY(-113px);
  transition: 0.2s;
}
.main-content-app,
.option-selected-conteiner {
  transition: 0.2s;
}

// #mainapp {
//   max-height: calc(100vh - 112px);
//   overflow-y: auto;
//   padding: 0px 3px 0px 20px;
// }

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  height: 5%;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, rgba(109,17,6,1) 1%, rgba(255,230,173,1) 100%);

  border-radius: 10px;
  min-height: 40px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255,230,173,1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255,230,173,1);
}
.nav-login {
  top: 0px !important;
}

.toast {
  color: white !important;
}
.side-bar:lang(en),
.main:lang(en) {
  left: 0 !important;
}
.lang-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
  .caret:lang(en) {
    left: unset !important;
    right: 0 !important;
  }
  .select-wrapper input.select-dropdown:focus {
    border: unset !important;
    margin: 0 !important;
  }
  .select-wrapper {
    width: 50px !important;
  }
  input {
    border: unset !important;
    margin: 0 !important;
  }
  svg {
    right: unset !important;
    left: 0;
  }
  ul {
    padding: 0;
    text-align: center;
    span {
      color: #1c1c1c !important;
    }
  }
}
@media (max-width: 601px) {
//   .app-body{
//   top: 0px !important;
// }
  .side-bar.col {
    padding: 0;
  }
  header.header-up,
  .main-content-app.header-up,
  .option-selected-conteiner.header-up {
    transform: translateY(-93px);
    transition: 0.2s;
  }
  .main-content-app,
  .option-selected-conteiner {
    transition: 0.2s;
  }
  // .footer {
  //   z-index: -3;
  // }
}

// .row .col {
//   float: var(--textfloat)
// }
/* Chrome, Safari, Edge, Opera hide arrow from number inputs*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox hide arrow from number inputs*/
input[type="number"] {
  -moz-appearance: textfield;
}

#pageStretcher {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  min-height: 100vh;
}
.toast-container {
  bottom: 0 !important;
}

.toast {
  color: unset;

  &.success {
    background-color: #70b132;
  }

  &.error {
    background-color: rgb(240, 51, 51);
  }
}

main {
  margin-top: 20px;
}
@media (min-width: 900px) {
  .side-bar {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 140px;
    max-width: 22% !important;
  }
  .main {
    max-width: 78% !important;
    flex: 0 0 78% !important;
  }
}

header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99999 !important;
  transition: 0.2s;
}

@media only screen and (max-width: 601px) {
  //mobile
  main {
    margin-top: unset;
    //  .main {
    // max-width: calc(100vw - 20px);
    // overflow-x: scroll;
    // }
  }
  //  .side-bar {
  //   position: -webkit-sticky !important;
  //   position: sticky !important;
  //   top: 112px;
  // }
}

[type="checkbox"].dolimo-style.filled-in:not(:checked)
  + span:not(.lever):after {
  border-radius: 7px;
  border: unset;
  box-shadow: 0 0 3px inset;
  right: 0;
}
[type="checkbox"].dolimo-style.filled-in:checked + span:not(.lever):after {
  border-radius: 7px;
  border: unset;
  background-color: $primaryColor;
  top: 3px;
  right: 0;
}

[type="checkbox"] + span:not(.lever) {
  padding: unset;
  padding-right: 35px;
}
[type="checkbox"] + span:not(.lever):lang(en) {
  padding-right: 0px;
  padding-left: 35px;
}
[type="checkbox"].filled-in:checked + span:not(.lever):before {
  left: unset;
  right: 10px;
}
[type="checkbox"].filled-in:checked + span:not(.lever):lang(en)::before {
  top: 2px;
  left: 0px;
}

.btn.dolimo-style {
  background-color: unset;
  background: $primaryBackgroundButtonColor;
  font-size: $mediumTextSize;
  color: white !important;
}

p {
  font-size: $mediumTextSize;
  color: $primaryTextColor;
}
html,
pre {
  // overflow-y: hidden !important;
  font-family: "Rubik";
  box-sizing: border-box;
}
body {
  margin: 0;
  // overflow-x: hidden;
  font-family: "Rubik";
}
input.dolimo-style {
  border: 1px solid lightgrey !important;
  border-radius: 10px !important;

  font-size: $smallTextSize !important;
  margin: 0 !important;

  padding-right: 10px !important;
}
input.dolimo-style:lang(en) {
  padding-right: 0px;
  padding-left: 10px !important;
}
textarea {
  padding: 10px !important;
}
.menu-collection {
  padding: 0 !important;
  border: unset !important;
  a {
    // color: #747474 !important;
    font-size: $mediumTextSize;
    border: unset !important;
    // border-radius: 8px;
    &.router-link-active {
      color: #73b235 !important;
      background-color: $primaryColorlight !important;
      // border-right: 5px solid $primaryColor !important;
    }
  }
}
input {
  max-width: 100% !important;
  box-sizing: border-box !important;
}
</style>
