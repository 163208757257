<template>
  <div class="table-info-wrapper">
    <label class="title-label"> {{ title }}</label>
    <span class="value"> {{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    value: {
      type: String,
      default: "⠀",
    },
  },
};
</script>
<style scoped lang="scss">
.table-info-wrapper {
  display: flex;
  flex-direction: column;
  font-size: $mediumTextSize;

  .title-label {
    color: $tableLabelColor;
    font-weight: bold;
  }

  .value {
    color: $tableValueColor;
  }
}
</style>
