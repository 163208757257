<template>
  <v-table fixed-header fixed-footer height="500px">
    <thead>
      <th>{{ $t(`invocies.table.columns.invoiceDate`) }}</th>
      <th>{{ $t(`invocies.table.columns.dueDate`) }}</th>
      <th>{{ $t(`invocies.table.columns.backLogDays`) }}</th>
      <th>{{ $t(`invocies.table.columns.order`) }}</th>
      <th>{{ $t(`invocies.table.columns.invoice`) }}</th>
      <th>{{ $t(`invocies.table.columns.details`) }}</th>
      <th>{{ $t(`invocies.table.columns.price`) }}</th>
      <th>{{ $t(`invocies.table.columns.currency`) }}</th>
    </thead>
    <tbody>
      <tr v-for="invoice in invoicesList" :key="invoice.ord">
        <td>{{ invoice?.curdate }}</td>
        <td>{{ invoice?.duedate }}</td>
        <td>{{ calculateBacklogDays(invoice) }}</td>
        <td>{{ invoice?.ord }}</td>
        <td>{{ invoice?.docno }}</td>
        <td class="remarks-col">{{ truncateText(invoice?.remarks) }}</td>
        <td>{{ invoice?.totalprice?.toLocaleString() }}</td>
        <td>₪</td>
      </tr>
    </tbody>
    <tfoot>
      <th class="sum-title">סה"כ</th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th class="sum-value font-weight-normal">
        {{ isNaN(sum("price")) ? "" : sum("price") }}
      </th>
      <th></th>
    </tfoot>
  </v-table>
</template>

<script>
import moment from "moment";
import { truncateText } from "@/utilities.js";

export default {
  props: {
    tableMode: {
      type: String,
      default: null,
    },
    invoicesList: {
      type: Array,
    },
  },
  methods: {
    calculateBacklogDays(invoice) {
      return invoice.duedate ? moment().diff(moment(invoice.duedate, 'DD/MM/YYYY'), "days") : "";
    },
    truncateText(text) {
      return truncateText(text, 100);
    },
    formatDate(senddate) {
      return moment(senddate).format("DD/MM/YY");
    },
    sum(type) {
      if (!this.invoicesList) return 0;
      const sum = this.invoicesList.reduce((acc, cur) => acc + cur[type], 0);
      return sum.toLocaleString();
    },
  },
};
</script>

<style lang="scss">
.v-table__wrapper {
  overflow-x: scroll;
  max-height: 500px;
}
</style>
<style scoped lang="scss">
@media only screen and (max-width: 601px) {
  thead th {
    font-size: 15px !important;
    min-width: 100px;
  }
  tbody td {
    font-size: 15px !important;
    min-width: 100px;
  }

  .sum-title,
  .sum-value {
    font-size: 15px !important;
  }
}
thead {
  border-radius: 0px 50px 50px 0px;
  background-color: #f5f5f5;
  position: sticky;
  th {
    font-size: $mediumTextSize;
    font-weight: 400;
    padding: 10px 0px;

    &:first-child {
      border-radius: 0px 50px 50px 0px;
    }
    &:last-child {
      border-radius: 50px 0px 0px 50px;
    }
  }
}
tr {
  height: 70px;
}
td {
  color: $orderLineColor;
  font-size: $mediumTextSize;
  text-align: center !important;
}
tfoot {
  border-radius: 0px 50px 50px 0px;
  background-color: #f5f5f5;
  color: #868686;
  th {
    font-size: $mediumTextSize;
    font-weight: 700;
    padding: 10px 0px;
    min-width: 150px;

    &:first-child {
      border-radius: 0px 50px 50px 0px;
    }
    &:last-child {
      border-radius: 50px 0px 0px 50px;
    }
  }
  .font-weight-normal {
    font-weight: 400;
  }
}

.remarks-col {
  min-width: 380px;
}
</style>
