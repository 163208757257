<template>
  <div>
    <TableWrapper
      mode="order"
      :sumCount="ordersList.length"
      title="הזמנות שבוצעו"
      icon="mdi mdi-text-box-check"
    >
      <OrderTableLine
        v-for="order in ordersList"
        :key="order.ord"
        :order="order"
        :orderId="order.ord"
        :currentExpandBtn="currentExpandBtn"
        @close-expand="setCurrentExpandBtn"
        @set-current-expand-btn="setCurrentExpandBtn"
      />
    </TableWrapper>
    <v-pagination
      v-model="page"
      :active-color="'blue'"
      :length="numberOfPages"
      :total-visible="7"
    ></v-pagination>
  </div>
</template>

<script>
import OrderTableLine from "./OrderTableLine.vue";
import TableWrapper from "./TableWrapper.vue";

export default {
  components: { OrderTableLine, TableWrapper },
  props: ["ordersList", "count"],
  data() {
    return {
      currentExpandBtn: 0,
      page: 1,
    };
  },
  watch: {
    page() {
      console.log(this.$route.query);

      var query = {
        ...this.$route.query,
        page: this.page,
      };
      console.log(query);
      this.$router.push({ path: this.$route.path, query: query });

      this.$emit("getOrdersPagination", query);
    },
    "$route.query": {
      immediate: true,
      handler() {
        this.page = 1;
      },
    },
    "$route.query.page": {
      immediate: true,
      handler() {
        console.log(typeof this.$route.query.page);
        if (this.$route.query.page) {
          if (this.$route.query.page > this.numberOfPages) {
            this.page = this.numberOfPages;
          } else this.page = Number(this.$route.query.page);
        }
      },
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.count / 30);
    },
    currentRoutePath() {
      return this.$route.path;
    },
    number() {
      return this.page;
    },
    currentRouteParams() {
      return this.$route.params;
    },
  },
  methods: {
    setCurrentExpandBtn(orderId) {
      this.currentExpandBtn = orderId;
    },
  },
};
</script>

<style lang="scss"></style>
