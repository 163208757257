import CustomerService from "../../services/CustomerService";

const updateCustomersDistLines = (state, customers) => {
  const uniqueDistLines = {};

  customers.forEach((customer) => {
    const lineKey = customer.DistLine.linekey;
    if (!uniqueDistLines[lineKey]) {
      uniqueDistLines[lineKey] = {
        id: lineKey,
        name: customer.DistLine.linedes,
      };
    }
  });

  state.customersDistLines = Object.values(uniqueDistLines);
};

const updateCustomersStatus = (state, customers) => {
  const customersStatus = {};

  customers.forEach((customer) => {
    const id = customer.isActive ? 1 : 0;
    if (!customersStatus[id]) {
      customersStatus[id] = {
        id: id,
        name: id ? "פעיל" : "לא פעיל",
      };
    }
  });

  state.customersStatus = Object.values(customersStatus);
};

const updateCustomersAgents = (state, customers) => {
  const uniqueAgents = {};

  customers.forEach((customer) => {
    const lineKey = customer.Agent.agent;
    if (!uniqueAgents[lineKey]) {
      uniqueAgents[lineKey] = {
        id: lineKey,
        name: customer.Agent.agentname,
      };
    }
  });

  state.customersAgents = Object.values(uniqueAgents);
};

const updateUniqueCustomer = (state, customers) => {
  const uniqueCustomers = {};

  customers.forEach((customer) => {
    const customerId = customer.cust;
    if (!uniqueCustomers[customerId]) {
      uniqueCustomers[customerId] = {
        id: customerId,
        name: customer.custdes,
      };
    }
  });

  state.uniqueCustomer = Object.values(uniqueCustomers);
};

export default {
  namespaced: true,
  state() {
    return {
      customers: [],
      customersDistLines: [],
      customersAgents: [],
      customersStatus: [],
      uniqueCustomer: [],
      activeCustomers: 0,
    };
  },
  mutations: {
    setActiveCustomers(state, activeCustomers = null) {
      if (activeCustomers) {
        state.activeCustomers = activeCustomers;
      } else {
        state.activeCustomers = state.customers.filter(
          (customer) => customer.isActive
        ).length;
      }
    },
    setCustomers(state, customers) {
      console.log("customers:");
      state.customers = customers;
      updateCustomersDistLines(state, customers);
      updateCustomersAgents(state, customers);
      updateUniqueCustomer(state, customers);
      updateCustomersStatus(state, customers);
      this.commit("Customers/setActiveCustomers");
    },
    setCustomersDistLines(state, customers) {
      updateCustomersDistLines(state, customers);
    },
    setCustomersAgents(state, customers) {
      updateCustomersAgents(state, customers);
    },
  },
  actions: {
    updateActiveCustomers(context, data) {
      context.commit("setActiveCustomers", data);
    },
    async getCustomers(context) {
      const getUserRole = context.rootGetters["AuthModule/getUserRole"];
      if (context.state.customers.length > 0 && getUserRole?.agentapp) {
        return;
      }
      try {
        const customers = await CustomerService.getCustomers();
        context.commit("setCustomers", customers.data.customers);
      } catch (err) {
        console.error("Error fetching customers:", err);
        return [];
      }
    },
  },
  getters: {
    getCustomers(state) {
      return state.customers;
    },
    getCustomersDistLines(state) {
      return state.customersDistLines;
    },
    getCustomersAgents(state) {
      return state.customersAgents;
    },
    getUniqueCustomer(state) {
      return state.uniqueCustomer;
    },
    getActiveCustomers(state) {
      return state.activeCustomers;
    },
    getCustomerStatus(state) {
      return state.customersStatus;
    },
  },
};
