import Api from "./Api";

export default {
  getCustomers() {
    return Api().get("customers");
  },
  getActiveCustomers() {
    return Api().get("active-customers-count");
  },
};
