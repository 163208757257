<template>
  <div class="contiainer-distubution">
    <div class="contiainer">
      <div class="right-side">
        <div v-if="!isAgents" class="title-wrapper">
          <div class="icon-backgorund">
            <v-icon color="black" icon="mdi-map-marker-radius-outline"></v-icon>
          </div>
          <span class="font-18 font-weight-bold text-start">{{
            truncateText(distributionArea?.linedes)
          }}</span>
        </div>
        <div v-else class="title-wrapper">
          <v-avatar color="#f5f5f5" icon="mdi-account" size="50"></v-avatar>
          <span class="black font-16 font-weight-bold text-start">
            {{ truncateText(distributionArea?.agentname) }}
          </span>
        </div>
        <div>
          <v-progress-circular
            class="pressnet-wrapper"
            color='#801512'
            :size="144"
            :width="12"
            :model-value="orderForTommrowPercentage"
            ><div>
              <div>
                <span class="black font-45 pressnet-number">
                  {{ distributionArea?.agentSales }}
                </span>
                <span class="black font-25">/</span>
                <span class="light-gray font-25">
                  {{ distributionArea?.agentgoalSales }}</span
                >
              </div>
              <div class="font-18 semi-bold mtn-10">
                {{ distributionArea?.agentSalesNls }} ₪
              </div>
            </div>
          </v-progress-circular>
          <div>
            <v-icon :color="'#801512'" icon="mdi-clipboard-text-clock"></v-icon>
            <span class="gray font-18">הזמנות למחר</span>
          </div>
        </div>
      </div>
      <div class="left-side">
        <div
          @click="gotoOrdersPage(distributionArea)"
          class="info-wrapper blue hover"
          :class="[
            distributionArea?.openOrdersCount > 0 ? 'cursor-pointer' : '',
          ]"
          :style="{
            'pointer-events':
              distributionArea?.openOrdersCount > 0 ? '' : 'none',
          }"
        >
          <div class="font-45">{{ distributionArea?.openOrdersCount }}</div>

          <div class="d-flex ga-2 mtn-10">
            <v-icon :color="'#801512'" icon="mdi-text-box"></v-icon>
            <span class="gray font-18">הזמנות פתוחות </span>
          </div>
        </div>
        <div
          @click="gotoCustomersPage(distributionArea)"
          class="info-wrapper green hover"
          :class="[
            distributionArea?.activeCustomersCount > 0 ? 'cursor-pointer' : '',
          ]"
          :style="{
            'pointer-events':
              distributionArea?.activeCustomersCount > 0 ? '' : 'none',
          }"
        >
          <div class="font-45">
            {{ distributionArea?.activeCustomersCount }}
          </div>
          <div class="d-flex ga-2 mtn-10">
            <v-icon :color="'#801512'" icon="mdi-account-group-outline"></v-icon
            ><span class="gray font-18">לקוחות פעילים</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isAgents" class="agents">
      <div class="title-agents">
        <div class="title-agents-right">
          <v-icon icon="mdi-chart-bar"></v-icon>
          <span class="gray font-18">יעד חודשי</span>
          <div class="font-18 semi-bold">
            {{ distributionArea?.agnetGoalSalesInNls }} ₪
          </div>
        </div>
        <div
          v-if="getUserRole?.agentapp"
          @click="isAgentModal = true"
          class="title-agents-left"
        >
          <v-icon :size="15" icon="mdi-pencil"></v-icon>
          <span class="edit">עריכה</span>
        </div>
      </div>
      <div>
        <v-progress-linear
          class="progress-linear"
          v-model="goalPerecntage"
          color="#656565"
          height="17"
        >
          <strong>{{ formatPrice(goalPerecntage) }}%</strong>
        </v-progress-linear>
        <div class="align-start">
          <span class="semi-bold"> הוזמן החודש </span>
          <span class="semi-bold">₪</span>
          <span class="semi-bold font-24">
            {{ formatPrice(distributionArea?.agnetSalesInNls) }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <AgentMonthlyGoalModal
    v-if="isAgentModal"
    :openModal="isAgentModal"
    :agnetGoalSalesInNls="distributionArea?.agnetGoalSalesInNls"
    :agentName="distributionArea?.agentname"
    @closeModal="isAgentModal = false"
    @updateNewAgentMonthlyGoal="updateNewAgentMonthlyGoal"
  />
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.cjs.js";
import AgentMonthlyGoalModal from "../UI/modals/AgentMonthlyGoalModal.vue";
import { truncateText } from "@/utilities.js";

export default {
  components: {
    AgentMonthlyGoalModal,
  },
  data() {
    return {
      isAgentModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getUserRole: "AuthModule/getUserRole",
    }),
    orderForTommrowPercentage() {
      return (
        (this.distributionArea?.agentSales /
          this.distributionArea?.agentgoalSales) *
        100
      );
    },
    goalPerecntage() {
      const value1 = Math.ceil(this.distributionArea?.agnetGoalSalesInNls);
      const value2 = Math.ceil(this.distributionArea?.agnetSalesInNls);
      const perectage = (value2 / value1) * 100;
      return isNaN(perectage) ? 0 : perectage;
    },
  },
  methods: {
    formatPrice(price) {
      if (Number.isInteger(price)) {
        return price.toString();
      } else {
        return price.toFixed(2);
      }
    },
    async updateNewAgentMonthlyGoal(newMonthlyGoal) {
      try {
        const response = await this.$store.dispatch(
          "ControlPanel/updateNewAgentMonthlyGoal",
          {
            newMonthlyGoal: newMonthlyGoal,
            agent: this.distributionArea.agent,
          }
        );
        if (response) this.isAgentModal = false;
      } catch (error) {
        console.log(error);
      }
    },
    gotoOrdersPage(distributionArea) {
      let query = {
        status: 2,
      };
      if (this.isAgents) query.agentId = distributionArea.agent;
      else query.distLine = distributionArea.linekey;
      this.$router.push({ path: "/orders/allorders", query });
    },
    gotoCustomersPage(distributionArea) {
      let query = {
        status: 1,
      };
      if (this.isAgents) query.agentId = distributionArea.agent;
      else query.distLine = distributionArea.linekey;
      this.$router.push({ path: "/customers/allcustomers", query });
    },
    truncateText(text) {
      return truncateText(text, 10);
    },
  },
  props: {
    isAgents: {
      type: Boolean,
      defult: false,
    },
    distributionArea: {
      type: Object,
      rqeuired: true,
    },
  },
};
</script>
<style lang="scss">
//vuetify
.v-progress-linear__background {
  background-color: #656565 !important;
}
.v-progress-linear__determinate {
  border-radius: 50px;
}
.v-progress-circular__underlay {
  color: #656565 !important;
}
</style>
<style scoped lang="scss">
//vuetify
.v-progress-linear__content {
  strong {
    color: #656565 !important;
    font-size: 14px;
    font-weight: 400;
  }
}
//normal
.contiainer-distubution {
  border: 1px solid $borderColor;
  border-radius: 20px;
  max-width: 440px;
  padding: 20px;
  .contiainer {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
  }

  .right-side {
    padding-left: 30px;
    border-left: 2px dashed $borderColor;
  }

  @media (max-width: 768px) {
    .right-side {
      padding-bottom: 10px;
    }

    .info-wrapper {
      padding: 6px !important;
    }

    .left-side,
    .right-side {
      width: 100% !important;
      padding: 0px !important;
    }

    .right-side {
      padding-bottom: 10px !important;
      padding-left: 10px !important;
    }

    .left-side {
      padding-right: 7px !important;
    }
  }

  .left-side {
    display: flex;
    padding-right: 30px;
    gap: 20px;
    flex-direction: column;
  }
}
.title-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
  .icon-backgorund {
    background: $primaryBackgroundColor;
    padding: 15px;
    border-radius: 50%;
  }
}
.pressnet-wrapper {
  margin-bottom: 8px;
}
.info-wrapper {
  background: $grayBackgroundColor;
  padding: 20px;
  text-align: start;
  border-radius: 20px;
  &.hover:hover {
    color: white;
    span,
    i {
      color: white !important;
      caret-color: white !important;
    }
  }
  &.blue:hover {
    background: $ordersMainColor;
  }
  &.green:hover {
    background: $customerMainColor;
  }
}
.title-agents {
  display: flex;
  justify-content: space-between;

  .title-agents-right {
    display: flex;
    gap: 5px;
  }
  .title-agents-left {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    .edit {
      text-decoration: underline;
    }
  }
}
.progress-linear {
  background: $grayBackgroundColor;
  margin-top: 10px;
  border-radius: 50px;
}
</style>
