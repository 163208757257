<template>
  <BaseSideBar :class="[isOpenMobileSideBar ? 'pa-3' : '']">
    <!-- list of Statuses -->
    <SideBarTitle
      key="title"
      mode="controlPanel"
      :isSideBarTitleShown="!needToCloseSideBar"
    />
    <FiltersDisplay closeBtnColor="#ff7465">
      <div class="date-filters mt-5">
        <datePickerButton
          mode="controlPanel"
          :header="' מתאריך'"
          :width="'150px'"
          @openModal="openDatePicker('fromDate')"
          :active="fromDate.trim().length > 0"
        >
          <div>
            <v-text-field
              class="flex-1-0 date-picker pr-3"
              hide-details="auto"
              :append-inner-icon="fromDate ? 'mdi-close' : 'mdi-calendar-end'"
              :model-value="formattedDate(fromDate, 'מתאריך')"
              variant="plain"
              style="pointer-events: none"
            ></v-text-field>
          </div>
        </datePickerButton>
        <datePickerButton
          mode="controlPanel"
          :header="'עד תאריך'"
          :width="'150px'"
          @openModal="openDatePicker('toDate')"
          :active="toDate.trim().length > 0"
        >
          <div>
            <v-text-field
              class="flex-1-0 date-picker pr-3"
              hide-details="auto"
              :append-inner-icon="toDate ? 'mdi-close' : 'mdi-calendar-end'"
              :model-value="formattedDate(toDate, 'עד תאריך')"
              variant="plain"
              style="pointer-events: none"
            ></v-text-field>
          </div>
        </datePickerButton>
      </div>

      <v-divider length="350px" class="ma-5 mr-0"></v-divider>

      <div class="d-flex flex-column ga-3">
        <SideBarList
            v-if="getUserRole?.agentapp"
          :Categories="getAgentRequestAgents"
          queryKey="agentId"
          defaultValue="סוכן"
          mode="agentRequests"
        />

        <SideBarList
          :Categories="getAgentRequestCustomers"
          queryKey="custId"
          defaultValue="לקוח"
          mode="agentRequests"
        />
      </div>
    </FiltersDisplay>
    <datePickerModal
      @update:modelValue="updateDate"
      @trigger-date-picker-update="updateDate"
      :openModal="openModalDatePicker"
    />
  </BaseSideBar>
</template>

<script>
import BaseSideBar from "./BaseSideBar.vue";
import datePickerButton from "@/components/UI/datePickerButton.vue";
import datePickerModal from "@/components/UI/modals/datePickerModal.vue";
import { mapGetters } from "vuex";
import SideBarTitle from "./SideBarTitle.vue";
import SideBarList from "./SideBarList.vue";
import { dateToDisplay } from "@/dates";
import moment from "moment";
import FiltersDisplay from "./FiltersDisplay.vue";

export default {
  components: {
    BaseSideBar,
    SideBarTitle,
    SideBarList,
    datePickerModal,
    datePickerButton,
    FiltersDisplay,
  },
  data() {
    return {
      fromDate: "",
      toDate: "",
      openModalDatePicker: false,
      ModalDatePickerType: "",
    };
  },
  mounted() {
    if (this.$route.query.fromDate) {
      this.fromDate = this.$route.query.fromDate;
    }
    if (this.$route.query.toDate) {
      this.toDate = this.$route.query.toDate;
    }
  },
  unmounted() {
    this.$store.commit("setIsOpenMobileSideBar", false);
  },
  methods: {
    updateDate(date) {
      this.openModalDatePicker = false;
      let query = { ...this.$route.query };
      if (this.ModalDatePickerType === "fromDate") {
        if (date) {
          this.fromDate = moment(date).toDate().toISOString();
          query.fromDate = this.fromDate;
        } else {
          this.fromDate = "";
          delete query.fromDate;
        }
      } else {
        if (date) {
          this.toDate = moment(date).toDate().toISOString();
          query.toDate = this.toDate;
        } else {
          this.toDate = "";
          delete query.toDate;
        }
      }
      this.$router.push({ path: "/agentRequests", query: query });
    },
    openDatePicker(type) {
      if (!this[type]) {
        this.ModalDatePickerType = type;
        this.openModalDatePicker = true;
      } else {
        this.updateDate(null);
      }
    },
    formattedDate(date, text) {
      if (!date) return text;
      return dateToDisplay(date);
    },
  },
  computed: {
    isLangHebrew() {
      return this.getUserLang == "he" ? true : false;
    },
    needToCloseSideBar() {
      if (
        (this.$store.getters.isMobile || this.$store.getters.isTablet) &&
        !this.$store.state.openMenu
      ) {
        // this.$store.commit("toggleMenu");
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters({
      getAgentRequestStatuses: "AgentRequests/getAgentRequestStatuses",
      getAgentRequestAgents: "AgentRequests/getAgentRequestAgents",
      getAgentRequestCustomers: "AgentRequests/getAgentRequestCustomers",
      isOpenMobileSideBar: "isOpenMobileSideBar",
      getUserRole: "AuthModule/getUserRole",
    }),
    getParamStatus() {
      return this.$route.params.status;
    },
  },
};
</script>

<style scoped lang="scss">
.title-section {
  padding: 0px 20px 0px 20px;
}
.orders-icon {
  color: $ordersMainColor;
  background-color: #44a5e233;
  border-radius: 50%;
  padding: 17px;
}
@media (max-width: 601px) {
  .side-bar-container {
    padding: unset;
  }
}
.menu-open-mobile-enter-from,
.menu-open-mobile-leave-to {
  max-height: 0px;
}
.menu-open-mobile-enter-active,
.menu-open-mobile-leave-active {
  transition: all 0.2s ease;
  overflow: hidden;
}
.menu-open-mobile-enter-to,
.menu-open-mobile-leave-from {
  max-height: 1000px;
}
h2 {
  font-size: 30px;
  font-weight: 700;
  color: $ordersMainColor;
  line-height: 35.55px;
  text-align: right;
}

.material-icons {
  font-size: unset;
}
.collection-item {
  text-decoration: none;
  span {
    font-weight: 400;
    padding: 10px;
  }
}
.menu-collection {
  .v-list-item-title {
    font-size: $mediumTextSize;
    font-weight: 400;
    line-height: 21.33px;
    text-align: right;
    color: $statusListColor;
  }
}

.date-filters {
  display: flex;
  gap: 40px;
}
</style>
