<template>
  <TableWrapper
    mode="customer"
    :sumCount="customersList.length"
    title="כל הלקוחות"
    icon="mdi-account-group-outline"
  >
    <CustomerTableLine
      v-for="customer in customersList"
      :key="customer.cust"
      :cust="customer.cust"
      :customer="customer"
      :currentExpandBtn="currentExpandBtn"
      @close-expand="setCurrentExpandBtn"
      @set-current-expand-btn="setCurrentExpandBtn"
    />
  </TableWrapper>
</template>

<script>
import CustomerTableLine from "./CustomerTableLine.vue";
import TableWrapper from "./TableWrapper.vue";

export default {
  components: { CustomerTableLine, TableWrapper },
  props: ["customersList"],
  data() {
    return {
      currentExpandBtn: -1,
    };
  },
  methods: {
    setCurrentExpandBtn(custId = -1) {
      this.currentExpandBtn = custId;
    },
  },
};
</script>

<style lang="scss"></style>
