<template>
  <transition name="fade">
    <div class="product-widget-main-container">
      <div class="product-widget-container">
        <div class="d-flex ga-6">
          <div class="d-flex product-line-container">
            <div :class="[productClass]" class="d-flex flex-wrap align-center">
              <img
                crossorigin="anonymous"
                @error="handleImageError"
                class="product-image"
                :src="getProductImage"
                alt="product image"
              />
              <div>
                <div class="info-container" v-if="line.Part.part">
                  <p class="info-title">{{ $t("ProductWidget.SKU") }}</p>
                  <p class="info-description">{{ line.Part.part }}</p>
                </div>
                <div
                  class="info-container moblie-info"
                  v-if="
                    line.Part.price > productPrice && $vuetify.display.mobile
                  "
                >
                  <p class="info-title">
                    {{ $t("ProductWidget.basePrice") }}
                  </p>
                  <p class="info-description">
                    {{ "₪" + formatPrice(line.Part.price) }}
                  </p>
                </div>
              </div>
              <div
                class="info-container"
                v-if="line.Part.partname && !$vuetify.display.mobile"
              >
                <p class="info-title">
                  {{ $t("ProductWidget.description") }}
                </p>
                <p class="info-description product-description">
                  {{ line.Part.partname }}
                </p>
              </div>
              <div class="info-container" v-if="orderNumber">
                <p class="info-title">
                  {{ $t("ProductWidget.orderNumber") }}
                </p>
                <p class="info-description">{{ orderNumber }}</p>
              </div>
              <div
                class="info-container"
                v-if="
                  line.Part.price > productPrice && !$vuetify.display.mobile
                "
              >
                <p class="info-title">
                  {{ $t("ProductWidget.basePrice") }}
                </p>
                <p class="info-description">
                  {{ "₪" + formatPrice(line.Part.price) }}
                </p>
              </div>
              <div class="info-container" v-if="custPriceCodePrecent">
                <p class="info-title">
                  {{ $t("ProductWidget.discount") }}
                </p>
                <p class="info-description">
                  {{ "%" + custPriceCodePrecent }}
                </p>
              </div>
              <div class="info-container" v-if="productPrice">
                <p class="info-title">
                  {{ $t("ProductWidget.finalPrice") }}
                </p>
                <p class="info-description">
                  {{ "₪" + formatPrice(productPrice) }}
                </p>
              </div>
              <div class="info-container" v-if="line.quant">
                <p class="info-title">
                  {{ $t("ProductWidget.quantity") }}
                </p>
                <p class="info-description">{{ quant }}</p>
              </div>
              <div class="info-container" v-if="line.quant">
                <p class="info-title">
                  {{ $t("ProductWidget.sumPrice") }}
                </p>
                <p class="info-description">
                  ₪{{ formatPrice(quant * productPrice) ?? 0 }}
                </p>
              </div>
              <div
                class="info-container full-width"
                v-if="line.Part.description && $vuetify.display.mobile"
              >
                <p class="info-title">
                  {{ $t("ProductWidget.description") }}
                </p>
                <p class="info-description product-description">
                  {{ line.Part.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <v-fade-transition :leave-absolute="true" :hide-on-leave="true">
          <div
            class="d-flex align-end ga-5 mt-0 flex-wrap refund-btn-actions"
            v-if="editAble"
          >
            <quantity-buttons
              :TheTitle="quantButtonTitle"
              :TheCounter="line.quant"
              @updateCounter="updateCounter"
            />
            <input
              v-if="mode === 'invoices'"
              :placeholder="$t('SingelInvoice.refundReason')"
              class="refund-reason-input"
              v-model="refundReason"
              @change="updateRefundReason"
            />
          </div>
          <div
            class="mt-5"
            v-if="line.refundRequest?.result && mode === 'invoices'"
          >
            <span class="refund-result" :class="[refundResultColor]">{{
              line.refundRequest.result
            }}</span>
          </div>
        </v-fade-transition>
      </div>
    </div>
  </transition>
</template>
<script>
import { baseUrl } from "../../config";
import QuantityButtons from "./QuantityButtons.vue";
export default {
  components: { QuantityButtons },
  emits: [
    "copy",
    "favorit",
    "openModal",
    "delete",
    "updateCounter",
    "updateRefundReason",
  ],
  props: {
    line: {
      type: Object,
    },
    cust: {
      type: Number,
    },
    orderNumber: null,
    orderReference: null,
    editAble: null,
    quantButtonTitle: null,
    mode: null,
  },
  data() {
    return {
      refundReason: "",
      quant: "",
    };
  },
  mounted() {
    if (this.line?.refundRequest?.refundReason) {
      this.refundReason = this.line.refundRequest.result;
    }
    if (this.line.quant) {
      this.quant = this.line.quant;
    }
  },
  methods: {
    handleImageError(evt) {
      evt.target.src = "/images/product-image.png";
    },
    formatPrice(price) {
      if (Number.isInteger(price) || typeof price === "string") {
        return price.toString();
      } else {
        return price.toFixed(2);
      }
    },
    updateCounter(quant) {
      // this.quant = quant;
      this.$emit("updateCounter", {
        ...this.line,
        quant: quant,
      });
    },
    updateRefundReason() {
      this.$emit("updateRefundReason", {
        ...this.line,
        refundReason: this.refundReason,
      });
    },
  },
  computed: {
    getProductImage() {
      const part = this.line.Part;
      if (part.image && part.image.includes(".")) {
        const src = part.image.split(".");
        const fileName = parseInt(src[0]);
        if (!isNaN(fileName)) {
          return `${baseUrl}images/` + fileName + "." + src[1];
        }
      }
      return "";
    },
    custPriceCodePrecent() {
      if (this.line.part?.CustPriceCode?.cust === this.cust)
        return this.line.part?.CustPriceCode?.precent;
      return null;
    },
    productPrice() {
      const part = this.line.Part;
      if (this.line.partPrice) return this.line.partPrice;
      let newProductPrice = part.price;
      if (this.isTheProductHasCustomer) {
        let foundCustPart = part.CustParts.find(
          (custPart) => custPart.cust === this.cust
        );
        if (foundCustPart?.specialprice)
          newProductPrice = foundCustPart.specialprice;
      } else if (part?.CustPriceCode?.cust === this.cust) {
        const discountAmount = (part.price * part.CustPriceCode.precent) / 100;
        newProductPrice = part.price - discountAmount;
      }

      return this.formatPrice(newProductPrice);
    },
    isTheProductHasCustomer() {
      return this.line.Part?.CustParts?.length > 0;
    },
    refundResultColor() {
      if (this.line?.refundRequest?.isApproved) {
        return "approved";
      } else {
        return "unapproved";
      }
    },
    productClass() {
      if (this.orderReference && this.orderNumber) {
        if (this.$vuetify.display.mobile)
          return "invoices-product moblie-product";
        else return "invoices-product";
      }
      if (this.$vuetify.display.mobile) return "default-product moblie-product";
      else return "default-product";
    },
    calculatedMaxProductWidth() {
      if (this.orderReference && this.orderNumber) return "65%";
      return "100%";
    },
    // finalPrice() {
    //   if (this.line.Part.discount) {
    //     return Math.floor(
    //       this.line.Part.price -
    //         this.line.Part.price * (this.line.Part.discount / 100)
    //     ).toFixed(2);
    //   } else {
    //     return this.line.Part.price.toFixed(2);
    //   }
    // },
  },
};
</script>
<style scoped lang="scss">
.refund-result {
  background: $primaryBackgroundColor;
  border-radius: 50px;
  padding: 8px;
  color: #36b079;
  width: max-content;
  font-size: $smallTextSize;
  font-weight: 400;
  line-height: 18.96px;
  text-align: right;
}

@media (max-width: 601px) {
  .refund-result {
    font-size: 15px;
  }
}

.refund-result.approved {
  color: #36b079;
}

.refund-result.unapproved {
  color: #d13257;
}

.refund-btn-actions {
  height: 50px;

  .wrapper {
    height: 100%;
  }
}
.refund-reason-input {
  max-width: 260px !important;
  border: 1px solid #fbecf0;
  padding: 5px 10px 5px 10px;
  box-shadow: 0px 0px 4px 0px #00000026 inset;
  border-radius: 15px;
  height: 100% !important;
  background: $primaryBackgroundColor;
}
.refund-reason-input::placeholder {
  color: #e1859b;
  font-size: $smallTextSize;
  font-weight: 400;
  line-height: 18.96px;
}

@media (max-width: 601px) {
  .refund-btn-actions {
    height: unset;
    margin-top: 0 !important;
  }

  .refund-reason-input {
    height: 50px !important;
  }
  .moblie-product {
    display: grid !important;
    grid-template-columns: 2fr 1fr;
    gap: 20px !important;
    .moblie-info {
      margin-top: 20px;
    }
    .full-width {
      grid-column: 1 / -1;
    }
  }
}

.invoices-product {
  gap: 35px;
  // margin-left: 10%;
}
.default-product {
  width: 100%;
  gap: 35px;
}
.info-container {
  // margin-left: 10px;
  // margin-right: 10px;
}
.product-image {
  width: 85px;
  height: 85px;
  object-fit: cover;
  border-radius: 20px;
}

.hide-on-med-and-down:first-child {
  .material-icons:hover {
    //     margin: 5px 0;
    //     position: relative;
    //     display: block;
    //     color: red;
    //     width: 0px;
    //     height: 0px;
    //     border-right: 10px solid transparent;
    //     border-bottom: 7px solid red;
    //     border-left: 10px solid transparent;
    //     transform: rotate(35deg);
  }
}

.product-widget-main-container {
  margin-bottom: 10px;
}

.product-widget-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid #ececec;
  padding: 20px;
  padding-left: 0px;
  gap: 20px;

  .product-title-container {
    h2 {
      font-size: $mediumTextSize;
      font-weight: 600;
      word-wrap: break-word;
    }
    p {
      font-size: $extraSmallTextSize;
    }
  }

  .product-info2-container {
    display: grid !important;
    grid-template-columns: repeat(5, max-content);
  }

  .info-title {
    font-size: $mediumTextSize;
    font-weight: 700;
    line-height: 21.33px;
    text-align: right;
  }
  .info-description {
    font-size: $mediumTextSize;
    color: $orderLineColor;
    font-weight: 400;
    line-height: 21.33px;
    text-align: right;
  }
}

.product-line-container {
  gap: 20px;
}

@media (max-width: 601px) {
  .default-product {
    gap: 19px;
  }

  .product-line-container {
    flex-direction: column;
  }
  .product-widget-container {
    margin-bottom: 25px;
    padding: 25px;

    div {
      justify-self: start;
    }
  }
}
</style>
